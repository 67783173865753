import React from 'react';
import Helmet from 'react-helmet';

import { Layout } from '../../components/Layout/Layout';

import { Hero } from '../../components/Loop/Hero';
import { Feature } from '../../components/Loop/Feature';
import { ShowcaseSection } from '../../components/Loop/Showcase';
// import { About as AboutSection } from '../../components/Loop/About';
import '../../styles/scss/style.scss'

const Loop = () => {

  return (
    <Layout>
      <Helmet>
        <title>Loop Design System UI Kit - Devwares</title>
        <meta name="title" content="Loop Design System UI Kit - Devwares" />
        <meta
          name="description"
          content="Free design system UI kit for busy designers and startups"
        />
        <meta property="og:title" content="Loop Design System UI Kit - Devwares" />
        <meta
          property="og:description"
          content="Free design system UI kit for busy designers and startups"
        />
        <meta property="twitter:title" content="Loop Design System UI Kit - Devwares" />
        <meta
          property="twitter:description"
          content="Free design system UI kit for busy designers and startups"
        />
        <meta property="og:site_name" content="Devwares" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@devwares" />
        <meta name="twitter:creator" content="@devwares" />
      </Helmet>
      <div className="container">
        <Hero />
        <Feature />
        <ShowcaseSection />
      </div>
    </Layout>
  );
};

export default Loop;
